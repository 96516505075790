export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:path(.*)',
    name: '/[...path]',
    component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/[...path].vue'),
    /* no children */
  },
  {
    path: '/admin',
    name: '/admin',
    component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/admin.vue'),
    children: [
      {
        path: 'applicants',
        name: '/admin/applicants',
        component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/admin/applicants.vue'),
        /* no children */
      },
      {
        path: 'batches',
        /* internal name: '/admin/batches' */
        /* no component */
        children: [
          {
            path: '',
            name: '/admin/batches/',
            component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/admin/batches/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            name: '/admin/batches/[id]',
            component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/admin/batches/[id].vue'),
            /* no children */
          },
          {
            path: 'create',
            name: '/admin/batches/create',
            component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/admin/batches/create.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'entry-codes',
        /* internal name: '/admin/entry-codes' */
        /* no component */
        children: [
          {
            path: '',
            name: '/admin/entry-codes/',
            component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/admin/entry-codes/index.vue'),
            /* no children */
          },
          {
            path: ':tag',
            name: '/admin/entry-codes/[tag]',
            component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/admin/entry-codes/[tag].vue'),
            /* no children */
          },
          {
            path: 'create',
            name: '/admin/entry-codes/create',
            component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/admin/entry-codes/create.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'rejection-queue',
        name: '/admin/rejection-queue',
        component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/admin/rejection-queue.vue'),
        /* no children */
      },
      {
        path: 'templates',
        name: '/admin/templates',
        component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/admin/templates.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/admin/login',
    name: '/admin.login',
    component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/admin.login.vue'),
    /* no children */
  },
  {
    path: '/admin/sign-in',
    name: '/admin.sign-in',
    component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/admin.sign-in.vue'),
    /* no children */
  },
  {
    path: '/challenge-details',
    /* internal name: '/challenge-details' */
    /* no component */
    children: [
      {
        path: ':token',
        name: '/challenge-details/[token]',
        component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/challenge-details/[token].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/complete-information',
    /* internal name: '/complete-information' */
    /* no component */
    children: [
      {
        path: ':token',
        name: '/complete-information/[token]',
        component: () => import('/home/runner/work/salento/salento/src/frontend/src/pages/complete-information/[token].vue'),
        /* no children */
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

